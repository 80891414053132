var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"font-weight-bold pb-1 indigo--text"},[_vm._v("Gestión")]),_c('h5',{staticClass:"font-weight-light"},[_vm._v("Gestión de Facturación")]),_c('v-form',{ref:"formulario",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"elevation-3 mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card-title',{staticClass:"font-weight-light text-overline"},[_vm._v(" Antecedentes Factura ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"items":_vm.tipo,"item-value":"tipo","item-text":"text","label":"Tipo","outlined":"","hide-details":"","rules":_vm.basic_validation_rules_select,"required":"","disabled":""},model:{value:(_vm.datosFormulario.tipo),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "tipo", $$v)},expression:"datosFormulario.tipo"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Nº Documento","outlined":"","hide-details":"","rules":_vm.basic_validation_rules_input,"required":""},model:{value:(_vm.datosFormulario.nro_documento),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "nro_documento", $$v)},expression:"datosFormulario.nro_documento"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Monto Factura","outlined":"","hide-details":""},model:{value:(_vm.datosFormulario.monto),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "monto", $$v)},expression:"datosFormulario.monto"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Factura","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","rules":_vm.basic_validation_rules_input,"required":""},model:{value:(_vm.datosFormulario.fecha_factura),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "fecha_factura", $$v)},expression:"datosFormulario.fecha_factura"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"locale":"es-es"},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.datosFormulario.fecha_factura),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "fecha_factura", $$v)},expression:"datosFormulario.fecha_factura"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"items":_vm.estados,"item-value":"tipo","item-text":"text","label":"Estado","outlined":"","hide-details":"","rules":_vm.basic_validation_rules_select,"required":""},model:{value:(_vm.datosFormulario.status),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "status", $$v)},expression:"datosFormulario.status"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[(_vm.datosFormulario.status == 2)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha de Pago","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","hide-details":"","rules":_vm.basic_validation_rules_input,"required":""},model:{value:(_vm.datosFormulario.fecha_pago),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "fecha_pago", $$v)},expression:"datosFormulario.fecha_pago"}},'v-text-field',attrs,false),on))]}}],null,false,2837147084),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"locale":"es-es"},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.datosFormulario.fecha_pago),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "fecha_pago", $$v)},expression:"datosFormulario.fecha_pago"}})],1):_vm._e()],1),(_vm.datosFormulario.status == 3)?_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Nº NC","outlined":"","hide-details":""},model:{value:(_vm.datosFormulario.nro_nc),callback:function ($$v) {_vm.$set(_vm.datosFormulario, "nro_nc", $$v)},expression:"datosFormulario.nro_nc"}})],1):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card-title',{staticClass:"font-weight-light text-overline"},[_vm._v(" Condiciones Asignadas ")]),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items":_vm.datosFormulario.conceptos,"no-results-text":_vm.noresult,"no-data-text":"Sin resultados que mostrar","search":_vm.search,"footer-props":{
                      'items-per-page-text': 'Resultados por página',
                      'items-per-page-all-text': 'Todos',
                    }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Condiciones")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Estás seguro de eliminar esta asociación?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.activo",fn:function(ref){
                    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getEstados(item.activo),"dark":"","small":""}},[_vm._v(_vm._s(_vm.getEstadosTexto(item.activo)))])]}},{key:"item.accion",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 blue--text",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"red--text text--darken-4",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1),_c('v-divider',{staticClass:"m-0"}),_c('v-card-actions',[_c('v-spacer'),(_vm.idDato)?_c('v-btn',{staticClass:"mb-2 indigo lighten-5 primary--text",attrs:{"loading":_vm.loading,"color":"indigo lighten-5","right":""},on:{"click":_vm.editData}},[_vm._v("Editar")]):_vm._e(),(!_vm.idDato)?_c('v-btn',{staticClass:"mb-2 indigo lighten-5 primary--text",attrs:{"loading":_vm.loading,"color":"indigo lighten-5","right":""},on:{"click":_vm.saveData}},[_vm._v("Grabar")]):_vm._e()],1)],1),(_vm.idDato)?_c('v-card',{staticClass:"elevation-3 mt-5"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card-title',{staticClass:"font-weight-light text-overline"},[_vm._v(" Documentos "),_c('v-spacer'),_c('v-btn',{staticClass:"mb-2 indigo lighten-5 primary--text",attrs:{"color":"indigo lighten-5"},on:{"click":function($event){_vm.dialogNuevoDocumento = true}}},[_vm._v("Nuevo Documento")])],1),_c('v-card-text',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers_documentos,"items":_vm.datosFormulario.archivos,"no-results-text":_vm.noresult,"no-data-text":"Sin resultados que mostrar","search":_vm.search,"footer-props":{
                      'items-per-page-text': 'Resultados por página',
                      'items-per-page-all-text': 'Todos',
                    }},scopedSlots:_vm._u([{key:"item.accion",fn:function(ref){
                    var item = ref.item;
return [(_vm.usuarioDB.data.role == 'ADMIN')?_c('v-icon',{staticClass:"mr-2 red--text text--darken-4",attrs:{"small":""},on:{"click":function($event){return _vm.deleteDocumento(item._id)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.fecha_creacion",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fecha_normal")(item.fecha_creacion))+" ")]}},{key:"item.archivo",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"green"}},[_c('v-icon',{on:{"click":function($event){return _vm.abrirArchivo(item.archivo)}}},[_vm._v("mdi-cloud-download")])],1)]}}],null,false,255364960)})],1)],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" Nuevo documento ")]),_c('v-card-text',{staticClass:"mt-4"},[_c('v-text-field',{attrs:{"label":"Nombre del documento","outlined":"","hide-details":""},model:{value:(_vm.nombreArchivo),callback:function ($$v) {_vm.nombreArchivo=$$v},expression:"nombreArchivo"}})],1),_c('v-card-text',{staticClass:"mt-4"},[_c('v-file-input',{ref:"file",attrs:{"hide-details":"","label":"Documento","type":"file","prepend-icon":"mdi-paperclip"},on:{"change":_vm.selectFile},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true),model:{value:(_vm.archivotemp),callback:function ($$v) {_vm.archivotemp=$$v},expression:"archivotemp"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mb-2 indigo lighten-5 primary--text",attrs:{"text":""},on:{"click":function($event){_vm.dialogNuevoDocumento = false}}},[_vm._v("Cerrar")]),_c('v-spacer'),(_vm.files == null)?_c('v-btn',{staticClass:"mb-2 indigo--text text--lighten-5 grey",attrs:{"text":"","disabled":""}},[_vm._v("Subir documento")]):_c('v-btn',{staticClass:"mb-2 indigo--text text--lighten-5 primary",attrs:{"text":""},on:{"click":function($event){return _vm.subirDocumento()}}},[_vm._v("Subir documento")])],1)],1)]}}]),model:{value:(_vm.dialogNuevoDocumento),callback:function ($$v) {_vm.dialogNuevoDocumento=$$v},expression:"dialogNuevoDocumento"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }