<template>
  <div>
    <h4 class="font-weight-bold pb-1 indigo--text">Gestión</h4>
    <h5 class="font-weight-light">Gestión de Facturación</h5>
    <v-form ref="formulario" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" lg="12">
          <v-card class="elevation-3 mt-5">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Antecedentes Factura
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="tipo"
                          v-model="datosFormulario.tipo"
                          item-value="tipo"
                          item-text="text"
                          label="Tipo"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                          disabled
                        ></v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Nº Documento"
                          v-model="datosFormulario.nro_documento"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_input"
                          required
                        >
                        </v-text-field>
                      </v-col>

                      <!--<v-col cols="12" lg="6">
                        <v-select
                          :items="mandantes"
                          v-model="datosFormulario.mandante"
                          item-value="_id"
                          item-text="nombre"
                          label="Mandante"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                          disabled
                        ></v-select>
                      </v-col>-->
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="Monto Factura"
                          v-model="datosFormulario.monto"
                          outlined
                          hide-details
                          
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="menu3"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Fecha de Factura"
                              v-model="datosFormulario.fecha_factura"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              hide-details
                              :rules="basic_validation_rules_input"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datosFormulario.fecha_factura"
                            locale="es-es"
                            @input="menu3 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          :items="estados"
                          v-model="datosFormulario.status"
                          item-value="tipo"
                          item-text="text"
                          label="Estado"
                          outlined
                          hide-details
                          :rules="basic_validation_rules_select"
                          required
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" lg="6">
                        <v-menu
                          v-model="menu4"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                          v-if="datosFormulario.status == 2"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              label="Fecha de Pago"
                              v-model="datosFormulario.fecha_pago"
                              prepend-inner-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              outlined
                              hide-details
                              :rules="basic_validation_rules_input"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="datosFormulario.fecha_pago"
                            locale="es-es"
                            @input="menu4 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="6" v-if="datosFormulario.status == 3">
                        <v-text-field
                          label="Nº NC"
                          v-model="datosFormulario.nro_nc"
                          outlined
                          hide-details
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-col>

              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Condiciones Asignadas
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-data-table
                      :headers="headers"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :items="datosFormulario.conceptos"
                      :no-results-text="noresult"
                      no-data-text="Sin resultados que mostrar"
                      :search="search"
                      :footer-props="{
                        'items-per-page-text': 'Resultados por página',
                        'items-per-page-all-text': 'Todos',
                      }"
                    >
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-toolbar-title>Condiciones</v-toolbar-title>
                          <v-spacer></v-spacer>

                          <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                              <v-card-title class="headline"
                                >Estás seguro de eliminar esta asociación?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete"
                                  >Cancelar</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteItemConfirm"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </template>
                      <template v-slot:item.activo="{ item }">
                        <v-chip :color="getEstados(item.activo)" dark small>{{
                          getEstadosTexto(item.activo)
                        }}</v-chip>
                        <!--<v-icon :color="getEstados(item.activo)" dark outlined>
            mdi-{{ getEstadosTexto(item.activo) }}
          </v-icon>-->
                      </template>
                      <template v-slot:item.accion="{ item }">
                        <v-icon small class="mr-2 blue--text" @click="editItem(item)">
                          mdi-pencil
                        </v-icon>
                        <v-icon
                          small
                          class="red--text text--darken-4"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
            <v-divider class="m-0"></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="idDato"
                @click="editData"
                right
                >Editar</v-btn
              >
              <v-btn
                :loading="loading"
                color="indigo lighten-5"
                class="mb-2 indigo lighten-5 primary--text"
                v-if="!idDato"
                @click="saveData"
                right
                >Grabar</v-btn
              >
            </v-card-actions>
          </v-card>

          <v-card class="elevation-3 mt-5" v-if="idDato">
            <v-row>
              <v-col cols="12" lg="12">
                <v-card-title class="font-weight-light text-overline">
                  Documentos
                  <v-spacer></v-spacer>
                  <v-btn
                    color="indigo lighten-5"
                    class="mb-2 indigo lighten-5 primary--text"
                    @click="dialogNuevoDocumento = true"
                    >Nuevo Documento</v-btn
                  >
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-data-table
                      :headers="headers_documentos"
                      :items="datosFormulario.archivos"
                      :no-results-text="noresult"
                      no-data-text="Sin resultados que mostrar"
                      :search="search"
                      :footer-props="{
                        'items-per-page-text': 'Resultados por página',
                        'items-per-page-all-text': 'Todos',
                      }"
                    >
                      <template v-slot:item.accion="{ item }">
                        <v-icon
                          small
                          v-if="usuarioDB.data.role == 'ADMIN'"
                          class="mr-2 red--text text--darken-4"
                          @click="deleteDocumento(item._id)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <template v-slot:item.fecha_creacion="{ item }">
                        {{ item.fecha_creacion | fecha_normal }}
                      </template>
                      <template v-slot:item.archivo="{ item }">
                        <v-btn icon color="green">
                          <v-icon @click="abrirArchivo(item.archivo)"
                            >mdi-cloud-download</v-icon
                          >
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="dialogNuevoDocumento"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-card-title class="primary white--text"> Nuevo documento </v-card-title>
          <v-card-text class="mt-4">
            <v-text-field
              label="Nombre del documento"
              v-model="nombreArchivo"
              outlined
              hide-details
            >
            </v-text-field>
          </v-card-text>
          <v-card-text class="mt-4">
            <v-file-input
              v-model="archivotemp"
              hide-details
              label="Documento"
              type="file"
              ref="file"
              @change="selectFile"
              prepend-icon="mdi-paperclip"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialogNuevoDocumento = false"
              class="mb-2 indigo lighten-5 primary--text"
              >Cerrar</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 grey"
              disabled
              v-if="files == null"
              >Subir documento</v-btn
            >
            <v-btn
              text
              class="mb-2 indigo--text text--lighten-5 primary"
              @click="subirDocumento()"
              v-else
              >Subir documento</v-btn
            >
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
const currencyMask = createNumberMask({
  prefix: "$",
  allowDecimal: false,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowNegative: true,
});
class datosReporte {
  constructor(datos = {}) {
    this.tipo = datos.tipo;
    this.nro_documento = datos.nro_documento;
    this.mandante = datos.mandante;
    this.detalle = datos.detalle;
    this.observacion = datos.observacion;
    this.monto = datos.monto;
    this.fecha_factura = datos.fecha_factura;
    this.conceptos = datos.conceptos;
    this.status = datos.status;
    this.fecha_pago = datos.fecha_pago;
    this.archivos = datos.archivos;
    this.nro_nc = datos.nro_nc;
  }
}

export default {
  data() {
    return {
      MaskMoneda: currencyMask,
      valid: true,
      basic_validation_rules_input: [
        (v) => !!v || "Campo obligatorio",
        (v) => (v && v.length <= 50) || "Campo requerido",
      ],
      basic_validation_rules_email: [
        (v) => !!v || "E-mail es obligatorio",
        (v) => /.+@.+\..+/.test(v) || "Debe ingresar un Email válido",
      ],
      basic_validation_rules_select: [(v) => !!v || "Campo obligatorio"],

      loading: false,
      idDato: null,
      ex11: true,

      tipo: [
        {
          tipo: "1",
          text: "Factura",
        },
        {
          tipo: "2",
          text: "NC",
        },
      ],

      estados: [
        {
          tipo: 1,
          text: "Facturado",
        },
        {
          tipo: 2,
          text: "Pagado",
        },
        {
          tipo: 3,
          text: "Anulado",
        },
      ],

      datosFormulario: new datosReporte(new datosReporte()),

      seleccionados: [],
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      menu3: false,
      menu4: false,
      noresult: "No existen resultados",
      search: "",
      sortBy: "ceco",
      sortDesc: false,
      headers: [
        {
          text: "Nº Proyecto",
          align: "start",
          value: "nro_proyecto",
        },
        {
          text: "Nombre Proyecto",
          align: "start",
          value: "nombre",
        },
        {
          text: "Concepto",
          align: "start",
          value: "condiciones[0].concepto",
        },
        {
          text: "Monto",
          align: "start",
          value: "condiciones[0].monto_cargado",
        },
      ],
      editedIndex: -1,
      editedItem: {
        nro_hes: "",
        concepto: "",
        monto: "",
        fecha_estimada_realizacion: "",
        fecha_realizacion: "",
        fecha_registro_realizacion: "",
        estado: 1,
      },
      dialog: false,
      dialogDelete: false,

      mandantes: [],

      tab: null,

      items2: [],

      offset: true,
      status_bd: "0",
      contactos_mandantes: [],
      encargados: [],
      files: null,
      nombreArchivo: "",
      dialogNuevoDocumento: false,
      headers_documentos: [
        {
          text: "Nombre Documento",
          align: "start",
          value: "nombre",
        },
        {
          text: "Subido por",
          align: "start",
          value: "responsable.nombre",
        },
        {
          text: "Fecha carga",
          align: "start",
          value: "fecha_creacion",
        },
        {
          text: "Documento",
          align: "start",
          value: "archivo",
        },
        {
          text: "Accion",
          value: "accion",
          sortable: false,
        },
      ],
      archivotemp: null,
    };
  },
  methods: {
    abrirArchivo(archivo) {
      window.open(process.env.VUE_APP_BASE_URL + "/files/facturas/" + archivo, "_blank");
    },
    selectFile(file) {
      this.files = file;
    },
    async subirDocumento() {
      this.loading = true;

      let config = {
        headers: {
          token: this.token,
          "Content-Type": "multipart/form-data;",
        },
      };

      const formData = new FormData();
      formData.append("file", this.files);
      formData.append("nombre", this.nombreArchivo);
      formData.append("idFactura", this.idDato);

      await axios
        .put("/facturas/subirDocumento/", formData, config)
        .then((response) => {
          this.loading = false;
          this.files = null;
          this.nombreArchivo = "";
          this.archivotemp = null;
          this.dialogNuevoDocumento = false;
          this.getData();
          this.$toast.success(
            "Grabado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    async deleteDocumento(item) {
      //this.loading = true;
      let config = {
        headers: {
          token: this.token,
        },
      };

      await axios
        .delete("/facturas/eliminarDocumento/" + item + "/" + this.idDato, config)
        .then((response) => {
          this.getData();
          this.$toast.success(
            "Eliminado con exito!",
            "",
            this.notificationSystem.options.error
          );
        })
        .catch((e) => {
          this.loading = false;
          this.$toast.error(
            "Ocurrio un error de sistema, vuelva a intentarlo",
            "Error",
            this.notificationSystem.options.error
          );
        });
    },
    validate() {
      this.$refs.formulario.validate();
    },
    getHeaders() {
      console.log(this.datosFormulario.hes);
      if (this.datosFormulario.hes) {
        this.headers = [
          {
            text: "Nº HES",
            align: "start",
            value: "nro_hes",
          },
          {
            text: "Concepto",
            align: "start",
            value: "concepto",
          },
          {
            text: "Monto",
            align: "start",
            value: "monto",
          },
          {
            text: "Fecha Estimada Realización",
            align: "start",
            value: "fecha_estimada_realizacion",
          },

          {
            text: "Accion",
            value: "accion",
            sortable: false,
          },
        ];
      } else if (!this.datosFormulario.hes) {
        console.log("cambio");
        this.headers = [
          {
            text: "Concepto",
            align: "start",
            value: "concepto",
          },
          {
            text: "Monto",
            align: "start",
            value: "monto",
          },
          {
            text: "Fecha Estimada Realización",
            align: "start",
            value: "fecha_estimada_realizacion",
          },

          {
            text: "Accion",
            value: "accion",
            sortable: false,
          },
        ];
      }
    },
    getMandantes() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      axios.get("/mandantes/mandante/", config).then((response) => {
        this.mandantes = response.data;
        //this.loading = false;
      });
    },

    getData() {
      let config = {
        headers: {
          token: this.token,
        },
      };
      if (this.idDato) {
        axios.get("/facturas/factura/" + this.idDato, config).then(async (response) => {
          console.log(response);
          //let v = response.monto.toString();
          //console.log(v);
          this.datosFormulario = new datosReporte(response.data);
          this.datosFormulario.fecha_factura = moment(
            this.datosFormulario.fecha_factura,
            "YYYY-MM-DD"
          ).format("YYYY-MM-DD");
          console.log(this.datosFormulario.fecha_pago);
          if (this.datosFormulario.fecha_pago) {
            this.datosFormulario.fecha_pago = moment(
              this.datosFormulario.fecha_pago,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD");
          } else {
            this.datosFormulario.fecha_pago = moment(new Date(), "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            );
          }

          //this.datosFormulario.monto = String(this.datosFormulario.monto);
          //this.datosFormulario.monto = "$2.700.000";
          //console.log(this.datosFormulario);
          /*for (let condiciones in this.datosFormulario.condiciones) {
            this.datosFormulario.condiciones[
              condiciones
            ].fecha_estimada_realizacion = moment(
              this.datosFormulario.condiciones[condiciones].fecha_estimada_realizacion,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD");
          }*/

          /*axios
            .get("/presupuestos/presupuesto/" + response.data.presupuestos[0]._id, config)
            .then((response) => {
              this.contactos_mandantes = response.data.mandante.contactos;
            });*/
        });
      } else {
      }
    },

    getDataInicial(nro_presupuesto) {
      console.log("llego");
      let config = {
        headers: {
          token: this.token,
        },
      };

      axios
        .get("/presupuestos/presupuesto/" + nro_presupuesto, config)
        .then((response) => {
          this.datosFormulario.presupuestos.push(response.data);
          this.datosFormulario.nombre = response.data.nombreProyecto;
          this.contactos_mandantes = response.data.mandante.contactos;
          console.log(response);
        });
    },

    async editData() {
      console.log("llego aca");
      this.loading = true;

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        await axios
          .put("/facturas/factura/" + this.idDato, this.datosFormulario, config)
          .then((response) => {
            this.$router.push({
              name: "facturacion",
            });

            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },

    async saveData() {
      console.log("llego aca");
      this.loading = true;

      if (this.$refs.formulario.validate()) {
        let config = {
          headers: {
            token: this.token,
          },
        };

        //console.log(this.datosFormulario);
        await axios
          .post("/facturas/factura/", this.datosFormulario, config)
          .then(async (response) => {
            this.$router.push({
              name: "facturacion",
            });

            this.$toast.success(
              "Grabado con exito!",
              "",
              this.notificationSystem.options.error
            );
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.error(
              "Ocurrio un error de sistema, vuelva a intentarlo",
              "Error",
              this.notificationSystem.options.error
            );
          });
      } else {
        this.loading = false;
        this.$toast.error(
          "Los campos en rojo son obligatorios, rellene y vuelva a intentarlo",
          "Error",
          this.notificationSystem.options.error
        );
      }
    },

    //Contactos
    editItem(item) {
      this.editedIndex = this.datosFormulario.condiciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datosFormulario.condiciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.datosFormulario.condiciones.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        //Object.assign(this.desserts[this.editedIndex], this.editedItem);

        Object.assign(
          this.datosFormulario.condiciones[this.editedIndex],
          this.editedItem
        );
      } else {
        this.datosFormulario.condiciones.push(this.editedItem);
      }
      this.close();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.idDato = this.$route.params.id;
    }
    this.getMandantes();
    this.getData();
  },
  mounted() {},
  computed: {
    ...mapState(["token", "usuarioDB", "itemsMenu", "notificationSystem"]),
    formTitle() {
      return this.editedIndex === -1 ? "Nueva Condición" : "Editar Condición";
    },
  },
  watch: {
    seleccionados: function (val) {
      this.datosFormulario.mods = [];
      for (let nodos of val) {
        this.datosFormulario.mods.push(nodos.id);
      }
    },
  },
  filters: {
    filtro_valor: function (valor, tipo_moneda) {
      if (tipo_moneda == "1") {
        return `U.F ${valor}`;
      } else {
        return `$ ${valor}`;
      }
    },
    fecha_normal: function (valor) {
      return moment(valor, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    fecha_normal_con_hora: function (valor) {
      return moment(valor, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>
<style>
.v-label--active {
  color: #1c67fd !important;
  font-weight: bold;
}
th span {
  color: "#FF0000" !important;
  border: 0px solid #7181b8;
  border-radius: 44px;
  padding: 5px;
  background: #e8eaf6;
}
#cardborde {
  border-top: 5px solid #e8eaf6 !important;
}
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}
</style>
